import React from 'react';
import Helmet from 'react-helmet'
import Layout from '@common/Layout';
import Navbar from '@common/Navbar/NavbarGracias';
import Footer from '@sections/Footer';
import styled from 'styled-components';

const Main = styled.div`
  height: 85vh;
  padding-top: 15vh;

  @media (max-width: ${props => props.theme.screen.md}) {
    height: 85vh;
  }
`

const StyleP = styled.p`
  text-align: center;
  padding-top: 5vh;
`

const Gracias = props => (
  <Layout>
    <Navbar />
    <Helmet>
      <title>Gracias</title>
      <meta name="description" content="pagina excito" />
    </Helmet>

    <Main>
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1 style={{textAlign:"center"}}>¡Gracias!</h1>
          </header>
          <StyleP>Gracias por contactarnos. Le responderemos pronto.</StyleP>
        </div>
      </section>
    </Main>
    <Footer />
  </Layout>
)

export default Gracias